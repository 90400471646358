import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachDetachRequest, SearchParams, UserLookup } from '@app/models';
import { forkJoin, Observable } from 'rxjs';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class UserRootService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/users`);
  }

  search(searchParams: SearchParams) {
    return this.http.get(
      `users?${generateSearchQuery(searchParams)}`,
    ) as Observable<IResponseSuccess<UserLookup[]>>;
  }

  loadUsersAcl(propertyId: number) {
    return this.http.get(`property/${propertyId}/acl/users`);
  }

  attachProperty(data: AttachDetachRequest[]) {
    return forkJoin(
      data.map((property) => {
        return this.http.post(
          `property/${property.property_id}/acl/user/${property.user_id}/attach`,
          property,
        );
      }),
    );
  }

  detachProperty(data: AttachDetachRequest[]) {
    return forkJoin(
      data.map((property) => {
        return this.http.post(
          `property/${property.property_id}/acl/user/${property.user_id}/detach`,
          property,
        );
      }),
    );
  }

  loadUserPropertiesAttached(property_id: number, user_id: number) {
    return this.http.get(
      `property/${property_id}/acl/user/${user_id}/presence`,
    );
  }

  deleteUser(user_id: number, property_id: number) {
    return this.http.delete(`property/${property_id}/acl/user/${user_id}`);
  }
}
